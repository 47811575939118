import React from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
const colors = {
  linearGradient1: 'linearGradient1',
  linearGradient2: 'linearGradient2',
}
type Props = {
  children?: React.ReactNode
  size?: number | string
  percentage?: number
  color?: keyof typeof colors
  strokeWidth?: number
  maxValue?: number
  enableBackground?: boolean
}

function CircularProgressBar({
  children,
  size,
  percentage = 100,
  color = 'linearGradient1',
  strokeWidth = 6,
  maxValue = 100,
  enableBackground,
}: Props) {
  return (
    <div style={{ width: size, height: size }}>
      <svg style={{ height: 0, position: 'absolute' }}>
        {/* define colors for CircularProgressbar */}
        <defs>
          <linearGradient
            id={colors.linearGradient1}
            x1="-6"
            y1="-4.29144e-08"
            x2="219.893"
            y2="-4.33791"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE986" />
            <stop offset="1" stopColor="#A77D3F" />
          </linearGradient>
          <linearGradient
            id={colors.linearGradient2}
            x1="37.1212"
            y1="-289.625"
            x2="-55.7358"
            y2="-265.975"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDD52" />
            <stop offset="1" stopColor="#B672D6" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgressbarWithChildren
        background={enableBackground}
        maxValue={maxValue}
        value={percentage}
        strokeWidth={strokeWidth}
        styles={{
          ...{
            text: { fontFamily: 'inherit' },
            trail: { stroke: '#E3D6E8', strokeLinecap: 'butt', strokeWidth: strokeWidth },
            path: {
              stroke: `url(#${color})`,
              strokeLinecap: 'butt',
              strokeWidth: strokeWidth,
            },
          },
          ...buildStyles({
            pathTransitionDuration: 0,
            strokeLinecap: 'butt',
            pathColor: `url(#${color})`,
            trailColor: '#E3D6E8',
            backgroundColor: '#FFFFFF',
          }),
        }}
      >
        {children}
      </CircularProgressbarWithChildren>
    </div>
  )
}

export default CircularProgressBar
