import { updateVeWomData, updateVeWomChartData, updateVeWomTransactions, updatePieChartData } from './actions'
import { AppState, AppDispatch } from './../index'
import { VeWomData } from './reducer'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VeWomChartDayData, VeWomPieChart, VeWomTransaction } from 'types'
import { useActiveNetworkVersion } from 'state/application/hooks'

export function useVeWomData(): [VeWomData | undefined, (vewomData: VeWomData) => void] {
  const [activeNetwork] = useActiveNetworkVersion()
  const vewomData: VeWomData | undefined = useSelector((state: AppState) => state.vewom[activeNetwork.id]?.data)

  const dispatch = useDispatch<AppDispatch>()
  const setVeWomDataData: (vewomData: VeWomData) => void = useCallback(
    (data: VeWomData) => dispatch(updateVeWomData({ data, networkId: activeNetwork.id })),
    [activeNetwork.id, dispatch]
  )
  return [vewomData, setVeWomDataData]
}

export function useVeWomChartData(): [VeWomChartDayData[] | undefined, (chartData: VeWomChartDayData[]) => void] {
  const [activeNetwork] = useActiveNetworkVersion()
  const chartData: VeWomChartDayData[] | undefined = useSelector(
    (state: AppState) => state.vewom[activeNetwork.id]?.chartData
  )

  const dispatch = useDispatch<AppDispatch>()
  const setChartData: (chartData: VeWomChartDayData[]) => void = useCallback(
    (chartData: VeWomChartDayData[]) => dispatch(updateVeWomChartData({ chartData, networkId: activeNetwork.id })),
    [activeNetwork.id, dispatch]
  )
  return [chartData, setChartData]
}

export function useVeWomPieChart(): [VeWomPieChart[] | undefined, (chartData: VeWomPieChart[]) => void] {
  const [activeNetwork] = useActiveNetworkVersion()
  const chartData: VeWomPieChart[] | undefined = useSelector(
    (state: AppState) => state.vewom[activeNetwork.id]?.pieChartData
  )

  const dispatch = useDispatch<AppDispatch>()
  const setChartData: (pieChartData: VeWomPieChart[]) => void = useCallback(
    (pieChartData: VeWomPieChart[]) => dispatch(updatePieChartData({ pieChartData, networkId: activeNetwork.id })),
    [activeNetwork.id, dispatch]
  )
  return [chartData, setChartData]
}

export function useVeWomTransactions(): [VeWomTransaction[] | undefined, (transactions: VeWomTransaction[]) => void] {
  const [activeNetwork] = useActiveNetworkVersion()
  const transactions: VeWomTransaction[] | undefined = useSelector(
    (state: AppState) => state.vewom[activeNetwork.id]?.transactions
  )
  const dispatch = useDispatch<AppDispatch>()
  const setTransactions: (transactions: VeWomTransaction[]) => void = useCallback(
    (transactions: VeWomTransaction[]) =>
      dispatch(updateVeWomTransactions({ transactions, networkId: activeNetwork.id })),
    [activeNetwork.id, dispatch]
  )
  return [transactions, setTransactions]
}
