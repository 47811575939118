import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import WMXLogo from '../../wombat-assets/tokens/WMX.svg'
import QUOLLLogo from '../../wombat-assets/tokens/QUOLL.svg'
import MGPLogo from '../../wombat-assets/tokens/MGP.svg'
import TBDLogo from '../../wombat-assets/tokens/TBD.svg'
import VEWOMLogo from '../../wombat-assets/tokens/veWOM.svg'
import SABLELogo from '../../wombat-assets/tokens/SABLE.svg'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { getTokenUrl } from '../../constants'
const StyledWombatLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

export default function CurrencyLogo({
  address,
  size = '32px',
  style,
  isVewom = false,
  ...rest
}: {
  address?: string
  size?: string
  style?: React.CSSProperties
  isVewom?: boolean
}) {
  const [activeNetwork] = useActiveNetworkVersion()
  const imgRef = useRef<null | HTMLImageElement>(null)
  useEffect(() => {
    const currentImgRef = imgRef.current
    if (currentImgRef) {
      currentImgRef.onerror = () => {
        currentImgRef.src = TBDLogo
      }
    }
  }, [])
  return (
    <StyledWombatLogo
      ref={imgRef}
      src={isVewom ? VEWOMLogo : getTokenUrl(activeNetwork.id, address?.toLowerCase() || '')}
      size={size}
      style={style}
      {...rest}
    />
  )
}

export function ProjectLogo({
  address,
  size = '32px',
  style,
  ...rest
}: {
  address?: string
  size?: string
  style?: React.CSSProperties
}) {
  const wombatLogo = useMemo(() => {
    switch (address) {
      case 'Wombex':
        return WMXLogo
      case 'Magpie':
        return MGPLogo
      case 'Quoll':
        return QUOLLLogo
      case 'Sable':
        return SABLELogo
      default:
        return undefined
    }
  }, [address])
  return <StyledWombatLogo src={wombatLogo} size={size} style={style} {...rest} />
}
