import { AssetData } from '../../state/assets/reducer'

// Rename KLAY to KAIA following the [merge between Klaytn and
// Finschia](https://www.binance.com/en-IN/square/post/2024-04-30-klaytn-finschia-kaia-6-7453768730521).
export function patchAssetDataKlayToKaia(assetData: AssetData): AssetData {
  const newAssetData = { ...assetData }
  if (newAssetData.name.includes('KLAY')) {
    newAssetData.name = newAssetData.name.replace('KLAY', 'KAIA')
  } else if (newAssetData.name.includes('Klay')) {
    newAssetData.name = newAssetData.name.replace('Klay', 'Kaia')
  }
  if (newAssetData.symbol.includes('KLAY')) {
    newAssetData.symbol = newAssetData.symbol.replace('KLAY', 'KAIA')
  }
  return newAssetData
}
