import styled, { css } from 'styled-components'

interface ColumnProps {
  gap?: string
}
const Column = styled.div<ColumnProps>`
  ${({ gap }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${gap || 'unset'};
  `}
`
export const ColumnCenter = styled(Column)`
  width: 100%;
  align-items: center;
`

export const AutoColumn = styled.div<{
  gap?: 'sm' | 'md' | 'lg' | string
  justify?: 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between'
  padding?: string
}>`
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: ${({ gap }) => (gap === 'sm' && '8px') || (gap === 'md' && '12px') || (gap === 'lg' && '24px') || gap};
  justify-items: ${({ justify }) => justify && justify};
  padding: ${({ padding }) => padding && padding};
`

export default Column
