import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import gql from 'graphql-tag'
import { VeWomPieChart } from 'types'

export const TOP_VEWOM = gql`
  query UserResult {
    users(subgraphError: allow, orderBy: veWomBalance, orderDirection: desc, first: 100) {
      id
      veWomBalance
      womBalance
    }
  }
`

export interface TopUser {
  id: string
  veWomBalance: number
  womBalance: number
}

interface UserResult {
  users: TopUser[]
}
export async function fetchVeWomUser(
  womClient: ApolloClient<NormalizedCacheObject>
): Promise<VeWomPieChart[] | undefined> {
  try {
    const { loading, error, data } = await womClient.query<UserResult>({
      query: TOP_VEWOM,
      fetchPolicy: 'cache-first',
    })

    if (error || loading || !data) {
      return undefined
    }

    const formattedUser = data.users.map((m: TopUser) => {
      const userEntry = {
        user: m.id,
        veWomBalance: m.veWomBalance,
        womBalance: m.womBalance,
      }
      return userEntry
    }, [])
    return [...formattedUser]
  } catch {
    return undefined
  }
}
