import { Transaction } from '../../types'

// Rename KLAY to KAIA following the [merge between Klaytn and
// Finschia](https://www.binance.com/en-IN/square/post/2024-04-30-klaytn-finschia-kaia-6-7453768730521).
export function patchTransactionKlayToKaia(transaction: Transaction): Transaction {
  const newTransaction = { ...transaction }
  if (newTransaction.token0Symbol.includes('KLAY')) {
    newTransaction.token0Symbol = newTransaction.token0Symbol.replace('KLAY', 'KAIA')
  }
  if (newTransaction.token1Symbol.includes('KLAY')) {
    newTransaction.token1Symbol = newTransaction.token1Symbol.replace('KLAY', 'KAIA')
  }
  return newTransaction
}
