import { VeWomPieChart } from 'types'

export type TopUser = {
  id: string
  veWomBalance: number
  label: string | null
}

export function getUserLabel(userAddress: string): string | null {
  switch (userAddress) {
    case '0xe3a7fb9c6790b02dcfa03b6ed9cda38710413569':
    case '0x24d2f6be2bf9cdf3627f720cf09d4551580c1ec1':
      return 'Wombex'
    case '0x664cc2bcae1e057eb1ec379598c5b743ad9db6e7':
    case '0x3cbfc97f87f534b42bb58276b7b5dcad29e57eac':
      return 'Magpie'
    case '0xe96c48c5fddc0dc1df5cf21d68a3d8b3aba68046':
    case '0x277cd4b508afbb75d182870409bbf610afab5c7b':
      return 'Quoll'
    default:
      return null
  }
}

export function formatTopUser(topUser: VeWomPieChart[], totalVeWomSupply: number): TopUser[] {
  const formattedTopUser: TopUser[] = topUser.slice(0, 10).map((user: VeWomPieChart) => {
    return {
      id: user.user,
      veWomBalance: +user.veWomBalance,
      label: getUserLabel(user.user),
    }
  })

  let sum = totalVeWomSupply
  formattedTopUser.forEach((obj) => {
    sum -= +obj.veWomBalance
  })

  const other: TopUser = {
    id: '',
    veWomBalance: sum,
    label: 'Others',
  }
  formattedTopUser.push(other)

  return formattedTopUser.sort((firstItem, secondItem) => secondItem.veWomBalance - firstItem.veWomBalance)
}
