import React from 'react'
import { RowFixed } from 'components/Row'
import styled from 'styled-components'
import { VeWomTransactionType } from 'types'

const SortText = styled.button<{ active: boolean }>`
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  margin-right: 0.75rem !important;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  padding: 0px;
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text3)};
  outline: none;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  font-family: 'Work Sans', sans-serif;
`

interface VeVomTransactionSort {
  txFilter: VeWomTransactionType | undefined
  setTxFilter: React.Dispatch<React.SetStateAction<VeWomTransactionType | undefined>>
}

/**@todo refactor: combine VeVomTransactionSort with Transactionsort */
export const VeVomTransactionSort = ({ txFilter, setTxFilter }: VeVomTransactionSort) => {
  return (
    <RowFixed>
      <SortText
        onClick={() => {
          setTxFilter(undefined)
        }}
        active={txFilter === undefined}
      >
        All
      </SortText>
      <SortText
        onClick={() => {
          setTxFilter(VeWomTransactionType.STAKE)
        }}
        active={txFilter === VeWomTransactionType.STAKE}
      >
        LOCK
      </SortText>
      <SortText
        onClick={() => {
          setTxFilter(VeWomTransactionType.UNSTAKE)
        }}
        active={txFilter === VeWomTransactionType.UNSTAKE}
      >
        UNLOCK
      </SortText>
    </RowFixed>
  )
}
