import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { ClientType, useDataClient } from 'state/application/hooks'

export const TOP_ASSETS = gql`
  {
    assets {
      id
    }
  }
`

interface TopAssetsResponse {
  assets: {
    id: string
  }[]
}

/**
 * Fetch top addresses by volume
 */
export function useTopAssetAddresses(): {
  loading: boolean
  error: boolean
  addresses: string[] | undefined
} {
  const dataClient = useDataClient()[ClientType.MAIN]

  const { loading, error, data } = useQuery<TopAssetsResponse>(TOP_ASSETS, { client: dataClient })

  const formattedData = useMemo(() => {
    if (data) {
      return data.assets.map((t) => t.id)
    } else {
      return undefined
    }
  }, [data])

  return {
    loading: loading,
    error: Boolean(error),
    addresses: formattedData,
  }
}
