import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MEDIA_WIDTHS } from 'theme'

interface ReturnType {
  isMobileSm: boolean
  isMobileLg: boolean
  isTabletSm: boolean
  isTabletLg: boolean
  isTabletXLg: boolean
}
function useBreakpoints(): ReturnType {
  const [breakpoint, setBreakpoint] = useState(1200)
  const resize = () => {
    setBreakpoint(window.innerWidth)
  }
  const location = useLocation()

  useEffect(() => {
    if (location) {
      setBreakpoint(window.innerWidth)
    }
  }, [location])

  useEffect(() => {
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const isMobileSm = breakpoint < MEDIA_WIDTHS.upToExtraSmall
  const isMobileLg = breakpoint < MEDIA_WIDTHS.upToSmall
  const isTabletSm = breakpoint < MEDIA_WIDTHS.upToMedium
  const isTabletLg = breakpoint < MEDIA_WIDTHS.upToLarge
  const isTabletXLg = breakpoint < MEDIA_WIDTHS.upToExtraLarge

  return { isMobileSm, isMobileLg, isTabletSm, isTabletLg, isTabletXLg }
}

export default useBreakpoints
