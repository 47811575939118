import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import gql from 'graphql-tag'
import { VeWomTransaction, VeWomTransactionType } from 'types'

const VEWOM_TRANSACTION = gql`
  {
    stakeWoms: enters(first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      id
      sender
      unlockTime
      veWomAmount
      womAmount
      timestamp
    }
    unstakeWoms: exits(first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      id
      sender
      unlockTime
      veWomAmount
      womAmount
      timestamp
    }
  }
`

export type Stake = {
  id: string
  sender: string
  unlockTime: string
  timestamp: string
  womAmount: string
  veWomAmount: string
  tokenForDisplay: string
  duration: string
}

export type Unstake = {
  id: string
  sender: string
  unlockTime: string
  timestamp: string
  womAmount: string
  veWomAmount: string
  tokenForDisplay: string
  duration: string
}

interface TransactionResults {
  stakeWoms: Stake[]
  unstakeWoms: Unstake[]
}

export async function fetchVeWomTransactions(
  womClient: ApolloClient<NormalizedCacheObject>
): Promise<VeWomTransaction[] | undefined> {
  try {
    const { data, error, loading } = await womClient.query<TransactionResults>({
      query: VEWOM_TRANSACTION,
      fetchPolicy: 'cache-first',
    })

    if (error || loading || !data) {
      return undefined
    }
    const formattedStake = data.stakeWoms.map((m: Stake) => {
      const stakeEntry = {
        type: VeWomTransactionType.STAKE,
        sender: m.sender,
        tokenForDisplay: 'WOM',
        hash: m.id,
        timestamp: m.timestamp,
        unlockTimestamp: m.unlockTime,
        duration: Number(m.unlockTime) - Number(m.timestamp),
        womAmount: parseFloat(m.womAmount),
        veWomAmount: parseFloat(m.veWomAmount),
      }
      return stakeEntry
    }, [])

    const formattedUnstake = data.unstakeWoms.map((m: Unstake) => {
      const unStakeEntry = {
        type: VeWomTransactionType.UNSTAKE,
        sender: m.sender,
        tokenForDisplay: 'veWOM',
        hash: m.id,
        timestamp: m.timestamp,
        unlockTimestamp: m.unlockTime,
        duration: Math.abs(Number(m.unlockTime) - Number(m.timestamp)),
        womAmount: parseFloat(m.womAmount),
        veWomAmount: parseFloat(m.veWomAmount),
      }
      return unStakeEntry
    }, [])

    const accum = [...formattedStake, ...formattedUnstake]
    return accum
  } catch {
    return undefined
  }
}
