import { VeWomData } from './reducer'
import { createAction } from '@reduxjs/toolkit'
import { VeWomChartDayData, VeWomPieChart, VeWomTransaction } from 'types'
import { SupportedNetwork } from 'constants/networks'

// vewom wide info
export const updateVeWomData = createAction<{ data: VeWomData; networkId: SupportedNetwork }>('vewom/updateVeWomData')
export const updateVeWomChartData = createAction<{ chartData: VeWomChartDayData[]; networkId: SupportedNetwork }>(
  'vewom/updateVeWomChartData'
)
export const updatePieChartData = createAction<{ pieChartData: VeWomPieChart[]; networkId: SupportedNetwork }>(
  'vewom/updatePieChartData'
)
export const updateVeWomTransactions = createAction<{ transactions: VeWomTransaction[]; networkId: SupportedNetwork }>(
  'vewom/updateVeWomTransactions'
)
