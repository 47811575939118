import { createAction } from '@reduxjs/toolkit'
import { AssetData, AssetChartEntry } from './reducer'
import { PriceChartEntry, Transaction } from 'types'
import { SupportedNetwork } from 'constants/networks'

// protocol wide info
export const updateAssetData = createAction<{ assets: AssetData[]; networkId: SupportedNetwork }>(
  'assets/updateAssetData'
)

// add asset address to byAddress
export const addAssetKeys = createAction<{ assetAddresses: string[]; networkId: SupportedNetwork }>(
  'assets/addAssetKeys'
)

// add list of pools asset is in
export const addPoolAddresses = createAction<{
  assetAddress: string
  poolAddresses: string[]
  networkId: SupportedNetwork
}>('assets/addPoolAddresses')

// tvl and volume data over time
export const updateChartData = createAction<{
  assetAddress: string
  chartData: AssetChartEntry[]
  networkId: SupportedNetwork
}>('assets/updateChartData')

// transactions
export const updateTransactions = createAction<{
  assetAddress: string
  transactions: Transaction[]
  networkId: SupportedNetwork
}>('assets/updateTransactions')

// price data at arbitrary intervals
export const updatePriceData = createAction<{
  assetAddress: string
  secondsInterval: number
  priceData: PriceChartEntry[] | undefined
  oldestFetchedTimestamp: number
  networkId: SupportedNetwork
}>('assets/updatePriceData')
