interface Data {
  dayID: string
  dailyLiability: string
  dayVolume: string
  asset: {
    id: string
    underlyingToken: {
      id: string
    }
    poolAddress: string | null
  }
}

export function generateIndexKey(assetAddress: string, dayId: string | number): string {
  return `${assetAddress}_${dayId}`
}

/**
 * Create a map from token address and day ID to corresponding daily liability
 * and volume.
 * */
export function indexAssetDayData(dataRecords: Data[]): { [key: string]: Data } {
  const result: { [key: string]: Data } = {}
  for (const record of dataRecords) {
    const key = generateIndexKey(record.asset.id, record.dayID)
    result[key] = record
  }
  return result
}

/**
 * With a determined token address and the day ID, find the token's previous
 * record. For example, we have the data for:
 *
 * - `day1`
 * - `day2`
 * - `day4`
 *
 * Then trying to find the previous data of `day4` would return `day2`'s data.
 * */
export function searchPreviousRecord(
  assetAddress: string,
  dayId: number,
  firstDayId: number,
  indexedDataRecords: { [key: string]: Data }
): Data {
  let previousDayId = dayId
  do {
    previousDayId -= 1
    const key = generateIndexKey(assetAddress, previousDayId)
    const record = indexedDataRecords[key]
    if (record) {
      return { ...record }
    }
  } while (previousDayId > firstDayId)

  const key = generateIndexKey(assetAddress, firstDayId)
  return indexedDataRecords[key]
}

export function searchPreviousLiability(
  assetAddress: string,
  dayId: number,
  firstDayId: number,
  indexedDataRecords: { [key: string]: Data }
): string {
  let previousDayId = dayId
  do {
    previousDayId -= 1
    const key = generateIndexKey(assetAddress, previousDayId)
    const record = indexedDataRecords[key]
    if (record && record.dailyLiability !== '0') {
      return record.dailyLiability
    }
  } while (previousDayId > firstDayId)

  const key = generateIndexKey(assetAddress, firstDayId)
  return indexedDataRecords[key].dailyLiability
}

/**
 * Returns records with liability "corrected" and additional records for missing
 * days.
 *
 * We need this since theoretically, subgraph should provide us complete daily
 * snapshots of assets. However, in reality, the subgraph returns data with:
 *
 * - Missing liability: liability is missing in the sense that it is zero, even
 * though the actual number is the day before's number.
 * - Missing records: for example, we receive records for day 1 2 3 5, but not
 * day 4.
 * */
export function fillMissingData(dataRecords: Data[]): Data[] {
  const indexedRecords = indexAssetDayData(dataRecords)

  const assetAddresses = new Set(dataRecords.map((record) => record.asset.id))
  const allDayIds = dataRecords.map((record) => Number.parseInt(record.dayID))
  const lastDayId = Math.max(...allDayIds)

  const resultRecords = []
  for (const assetAddress of assetAddresses) {
    const dayIds = Object.values(indexedRecords)
      .filter((record) => record.asset.id === assetAddress)
      .map((record) => Number.parseInt(record.dayID))
    const firstDayId = Math.min(...dayIds)
    // We use the token's firstDayID as the start to avoid a bug where we try to
    // find the previous data before it even has data within the subgraph.
    for (let dayId = firstDayId; dayId <= lastDayId; dayId++) {
      const key = generateIndexKey(assetAddress, dayId)
      let record = indexedRecords[key]
      if (!record) {
        record = {
          ...searchPreviousRecord(assetAddress, dayId, firstDayId, indexedRecords),
          dayID: dayId.toString(),
        }
      }
      if (record.dailyLiability === '0') {
        record = {
          ...record,
          dailyLiability: searchPreviousLiability(assetAddress, dayId, firstDayId, indexedRecords),
        }
      }
      resultRecords.push(record)
    }
  }

  return resultRecords
}
