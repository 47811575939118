import React from 'react'
import { RowFixed } from 'components/Row'
import styled from 'styled-components'
import { TransactionType } from 'types'

const SortText = styled.button<{ active: boolean }>`
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  margin-right: 0.75rem !important;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  padding: 0px;
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text3)};
  outline: none;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  font-family: 'Work Sans', sans-serif;
`

interface TransactionSort {
  txFilter: TransactionType | undefined
  setTxFilter: React.Dispatch<React.SetStateAction<TransactionType | undefined>>
}

export const TransactionSort = ({ txFilter, setTxFilter }: TransactionSort) => {
  return (
    <RowFixed>
      <SortText
        onClick={() => {
          setTxFilter(undefined)
        }}
        active={txFilter === undefined}
      >
        All
      </SortText>
      <SortText
        onClick={() => {
          setTxFilter(TransactionType.SWAP)
        }}
        active={txFilter === TransactionType.SWAP}
      >
        Swaps
      </SortText>
      <SortText
        onClick={() => {
          setTxFilter(TransactionType.MINT)
        }}
        active={txFilter === TransactionType.MINT}
      >
        Deposits
      </SortText>
      <SortText
        onClick={() => {
          setTxFilter(TransactionType.BURN)
        }}
        active={txFilter === TransactionType.BURN}
      >
        Withdrawals
      </SortText>
    </RowFixed>
  )
}
