import { useQuery } from '@apollo/client'
import { BNBNetworkInfo } from 'constants/networks'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { ClientType, useActiveNetworkVersion, useDataClient, useWomTokenClient } from 'state/application/hooks'
import { VeWomData } from 'state/vewom/reducer'

export const VEWOM_DATA = gql`
  query VeWomField {
    veWoms(subgraphError: allow) {
      totalSupply: currentTotalSupply
      totalLockedWom: currentTotalWomLocked
      numberOfUser: userCount
      numberOfLockedCycle: lockCount
    }
  }
`

export const WOM_DATA = gql`
  query WomField {
    womtokens(subgraphError: allow) {
      circulatingWom: circulatingSupply
    }
  }
`

export interface VeWomField {
  totalSupply: number
  totalLockedWom: number
  numberOfUser: number
  numberOfLockedCycle: number
}

interface VeWomDataResponse {
  veWoms: VeWomField[]
}

function parseVeWomDataResponse(response: VeWomField[] | undefined): VeWomField {
  let totalSupply = 0,
    totalLockedWom = 0,
    numberOfUser = 0,
    numberOfLockedCycle = 0

  if (Array.isArray(response) && response.length >= 1) {
    totalSupply = response[response.length - 1].totalSupply
    totalLockedWom = response[response.length - 1].totalLockedWom
    numberOfUser = response[response.length - 1].numberOfUser
    numberOfLockedCycle = response[response.length - 1].numberOfLockedCycle
  }
  return { totalSupply, totalLockedWom, numberOfUser, numberOfLockedCycle }
}

export function useFetchVeWomData(): {
  loading: boolean
  error: boolean
  data: VeWomData | undefined
} {
  const [activeNetwork] = useActiveNetworkVersion()
  const veWomClient = useWomTokenClient()
  const dataClient = useDataClient()[ClientType.MAIN]
  const {
    loading: veWomLoading,
    error: veWomError,
    data: veWomData,
  } = useQuery<VeWomDataResponse>(VEWOM_DATA, {
    client: activeNetwork == BNBNetworkInfo ? veWomClient : dataClient,
  })
  const parsedVeWomData = veWomData?.veWoms

  const anyError = Boolean(veWomError)
  const anyLoading = Boolean(veWomLoading)
  const allParsed = Boolean(parsedVeWomData)
  const formattedData: VeWomData | undefined = useMemo(() => {
    if (anyError || anyLoading || !allParsed) {
      return undefined
    }
    const veWomData = parseVeWomDataResponse(parsedVeWomData)
    const results = veWomData ? { veWomData } : undefined
    return results
  }, [allParsed, anyError, anyLoading, parsedVeWomData])
  return {
    loading: anyLoading,
    error: anyError,
    data: formattedData,
  }
}
