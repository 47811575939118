// FFCD1A
import React from 'react'

export const overviewSvg = ({ fill }: { fill: string }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.95556 9.9151L0.989258 6.20938V4.33241L7.74784 1.43164H12.5293L19.2434 4.33241V6.2539L12.2437 9.88543L7.95556 9.9151Z"
      stroke={fill}
      strokeWidth="1.49"
      strokeLinejoin="round"
    />
    <path
      d="M19.2434 10.4937L12.2437 14.1253L7.95556 14.1586L0.989258 10.4492"
      stroke={fill}
      strokeWidth="1.49"
      strokeLinejoin="round"
    />
    <path
      d="M19.2434 14.7379L12.2437 18.3694L7.95556 18.3991L0.989258 14.6934"
      stroke={fill}
      strokeWidth="1.49"
      strokeLinejoin="round"
    />
  </svg>
)

export const vevomSvg = ({ fill }: { fill: string }) => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.29514 6.64003H2.8195C2.31063 6.64003 1.89844 6.22784 1.89844 5.71897V2.24333C1.89844 1.73445 2.31063 1.32227 2.8195 1.32227H6.29514C6.80401 1.32227 7.2162 1.73445 7.2162 2.24333V5.71897C7.22129 6.22784 6.80401 6.64003 6.29514 6.64003Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M20.3405 13.6107H16.8649C16.356 13.6107 15.9438 13.1985 15.9438 12.6896V9.21403C15.9438 8.70516 16.356 8.29297 16.8649 8.29297H20.3405C20.8494 8.29297 21.2616 8.70516 21.2616 9.21403V12.6896C21.2616 13.1934 20.8494 13.6107 20.3405 13.6107Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M13.4197 20.765H9.94401C9.43514 20.765 9.02295 20.3528 9.02295 19.8439V16.3683C9.02295 15.8595 9.43514 15.4473 9.94401 15.4473H13.4197C13.9285 15.4473 14.3407 15.8595 14.3407 16.3683V19.8439C14.3407 20.3528 13.9285 20.765 13.4197 20.765Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M13.2564 6.28284L9.83675 6.90366C9.33297 6.99525 8.8546 6.6645 8.763 6.16072L8.14219 2.74105C8.05059 2.23726 8.38137 1.75893 8.88516 1.66733L12.3048 1.04648C12.8086 0.954884 13.2869 1.28567 13.3785 1.78945L13.9994 5.20912C14.091 5.71291 13.7602 6.19124 13.2564 6.28284Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M6.19874 13.4779L2.73329 13.7221C2.22441 13.7578 1.78169 13.3761 1.74607 12.8672L1.50182 9.40178C1.46619 8.8929 1.84785 8.45018 2.35672 8.41455L5.82217 8.17027C6.33105 8.13465 6.77377 8.51634 6.80939 9.02521L7.05365 12.4907C7.08927 12.9995 6.70761 13.4423 6.19874 13.4779Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M19.938 6.78107L16.498 6.27729C15.9942 6.20604 15.6431 5.73279 15.7194 5.229L16.2232 1.78899C16.2944 1.28521 16.7677 0.934096 17.2715 1.01043L20.7115 1.51421C21.2153 1.58545 21.5664 2.05871 21.4901 2.5625L20.9863 6.00251C20.9099 6.5012 20.4418 6.85231 19.938 6.78107Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M5.93869 20.765H2.46306C1.95418 20.765 1.54199 20.3528 1.54199 19.8439V16.3683C1.54199 15.8595 1.95418 15.4473 2.46306 15.4473H5.93869C6.44757 15.4473 6.85976 15.8595 6.85976 16.3683V19.8439C6.85976 20.3528 6.44757 20.765 5.93869 20.765Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M19.938 20.9901L16.498 20.4863C15.9942 20.415 15.6431 19.9418 15.7194 19.438L16.2232 15.998C16.2944 15.4942 16.7677 15.1431 17.2715 15.2194L20.7115 15.7232C21.2153 15.7944 21.5664 16.2677 21.4901 16.7715L20.9863 20.2115C20.9099 20.7153 20.4418 21.0664 19.938 20.9901Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M13.0181 13.828L9.57809 13.3242C9.0743 13.2529 8.72316 12.7797 8.79949 12.2759L9.30327 8.83587C9.37452 8.33208 9.84777 7.98097 10.3516 8.0573L13.7916 8.56109C14.2954 8.63233 14.6465 9.10558 14.5702 9.60937L14.0664 13.0494C13.99 13.5532 13.5219 13.9043 13.0181 13.828Z"
      stroke={fill}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
  </svg>
)

export const assetsSvg = ({ fill }: { fill: string }) => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1682_81494)">
      <path
        d="M15.8253 8.44719L14.9361 6.99023H8.30795V11.6931H15.3543V16.3101H8.71651L7.83691 14.7008"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M11.8589 4.32812L11.8589 19.3064" stroke={fill} strokeWidth="1.5" strokeLinejoin="round" />
    </g>
    <g clipPath="url(#clip1_1682_81494)">
      <path
        d="M5.63108 4.25298V2.04492H17.1787V4.25298H19.292V6.52712H21.2157V17.6886H19.1024V19.836H16.9944V21.4769H5.91286V19.836H3.61527V17.6886H1.78369V6.52712H3.75616V4.25298H5.63108Z"
        stroke={fill}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1682_81494">
        <rect width="8.65413" height="14.6454" fill="white" transform="translate(7.1709 4.32812)" />
      </clipPath>
      <clipPath id="clip1_1682_81494">
        <rect width="22.5007" height="22.5007" fill="white" transform="translate(0.249023)" />
      </clipPath>
    </defs>
  </svg>
)
