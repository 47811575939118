import React from 'react'
import DoughnutChart, { DoughnutChartData } from 'components/charts/DoughnutChart'
import useBreakpoints from 'hooks/useBreakpoints'
import { TYPE } from 'theme'
import CircularProgressBar from 'components/CircularProgressBar'
import { formatAmount } from 'utils/numbers'

interface VeWomDoughnutChart {
  top10HoldersDoughnutChartData: DoughnutChartData
  veWomSupplyHover: number | undefined
}
export const VeWomDoughnutChart = ({ top10HoldersDoughnutChartData, veWomSupplyHover }: VeWomDoughnutChart) => {
  const { isMobileSm } = useBreakpoints()

  return (
    <>
      {isMobileSm ? (
        <DoughnutChart data={top10HoldersDoughnutChartData} maxWidth="450px" margin="30px auto" size="90%">
          <CircularProgressBar percentage={100}>
            <TYPE.largeHeader fontWeight={700} fontSize={16} textAlign="center">
              veWOM Supply
            </TYPE.largeHeader>
            <TYPE.mediumHeader fontWeight={700} fontSize={24} textAlign="center">
              {formatAmount(veWomSupplyHover, 2)}
            </TYPE.mediumHeader>
          </CircularProgressBar>
        </DoughnutChart>
      ) : (
        <DoughnutChart data={top10HoldersDoughnutChartData} maxWidth="450px" margin="0 auto" size="100%">
          <CircularProgressBar percentage={100}>
            <TYPE.largeHeader fontWeight={700} fontSize={24} textAlign="center">
              veWOM Supply
            </TYPE.largeHeader>
            <TYPE.mediumHeader fontWeight={700} fontSize={32} textAlign="center">
              {formatAmount(veWomSupplyHover, 2)}
            </TYPE.mediumHeader>
          </CircularProgressBar>
        </DoughnutChart>
      )}
    </>
  )
}
