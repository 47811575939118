import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import gql from 'graphql-tag'
import { Transaction, TransactionType } from 'types'

const GLOBAL_TRANSACTIONS = gql`
  {
    swaps(first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      fromToken {
        id
        symbol
      }
      toToken {
        id
        symbol
      }
      sentFrom
      fromAmount
      toAmount
      amount: amountUSD
    }
    deposits(first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      token {
        id
        symbol
      }
      sentFrom
      amount
      amountUSD
    }
    withdraws(first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      token {
        id
        symbol
      }
      sentFrom
      amount
      amountUSD
    }
  }
`

export type Swap = {
  timestamp: string
  id: string
  fromToken: {
    id: string
    symbol: string
  }
  toToken: {
    id: string
    symbol: string
  }
  sentFrom: string
  fromAmount: string
  toAmount: string
  amount: string
}

export type Deposit = {
  timestamp: string
  id: string
  token: {
    id: string
    symbol: string
  }
  sentFrom: string
  amount: string
  amountUSD: string
}

export type Withdraw = {
  timestamp: string
  id: string
  token: {
    id: string
    symbol: string
  }
  sentFrom: string
  amount: string
  amountUSD: string
}

interface TransactionResults {
  swaps: Swap[]
  deposits: Deposit[]
  withdraws: Withdraw[]
}

export async function fetchTopTransactions(
  client: ApolloClient<NormalizedCacheObject>
): Promise<Transaction[] | undefined> {
  try {
    const { data, error, loading } = await client.query<TransactionResults>({
      query: GLOBAL_TRANSACTIONS,
      fetchPolicy: 'cache-first',
    })

    if (error || loading || !data) {
      return undefined
    }

    const formattedSwaps = data.swaps.map((m: Swap) => {
      const swapEntry = {
        hash: m.id,
        type: TransactionType.SWAP,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.fromToken.symbol,
        token1Symbol: m.toToken.symbol,
        token0Address: m.fromToken.id,
        token1Address: m.toToken.id,
        amountUSD: parseFloat(m.amount),
        amountToken0: parseFloat(m.fromAmount),
        amountToken1: parseFloat(m.toAmount),
      }
      return swapEntry
    }, [])
    const formattedDeposits = data.deposits.map((m: Deposit) => {
      const depositEntry = {
        type: TransactionType.MINT,
        hash: m.id,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.token.symbol,
        token1Symbol: m.token.symbol,
        token0Address: m.token.id,
        token1Address: m.token.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount),
        amountToken1: parseFloat(m.amount),
      }
      return depositEntry
    }, [])
    const formattedWithdraws = data.withdraws.map((m: Withdraw) => {
      const withdrawEntry = {
        type: TransactionType.BURN,
        hash: m.id,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.token.symbol,
        token1Symbol: m.token.symbol,
        token0Address: m.token.id,
        token1Address: m.token.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount),
        amountToken1: parseFloat(m.amount),
      }
      return withdrawEntry
    }, [])
    const accum = [...formattedSwaps, ...formattedDeposits, ...formattedWithdraws]
    return accum
  } catch {
    return undefined
  }
}
