import { useVeWomData, useVeWomChartData, useVeWomTransactions, useVeWomPieChart } from './hooks'
import { useEffect } from 'react'
import { fetchVeWomTransactions } from 'data/vewom/transaction'
import { ClientType, useActiveNetworkVersion, useDataClient, useWomTokenClient } from 'state/application/hooks'
import { useFetchVeWomData } from 'data/vewom/overview'
import { useFetchVeWomChartData } from 'data/vewom/chartData'
import { fetchVeWomUser } from 'data/vewom/topUser'
import { BNBNetworkInfo } from 'constants/networks'

export default function Updater(): null {
  // client for data fetching
  const [activeNetwork] = useActiveNetworkVersion()
  const dataClient = useDataClient()[ClientType.MAIN]
  const bnbClient = useWomTokenClient()
  const womTokenClient = activeNetwork == BNBNetworkInfo ? bnbClient : dataClient
  const [veWomData, updateVeWomData] = useVeWomData()
  const { data: fetchedVeWomData, error, loading } = useFetchVeWomData()
  const [topUser, updateTopUser] = useVeWomPieChart()
  const [chartData, updateChartData] = useVeWomChartData()
  const { data: fetchedChartData, error: chartError } = useFetchVeWomChartData()
  const [transactions, updateTransactions] = useVeWomTransactions()
  // update overview data if available and not set
  useEffect(() => {
    if (fetchedVeWomData && !loading && !error) {
      updateVeWomData(fetchedVeWomData)
    }
  }, [error, fetchedVeWomData, loading, updateVeWomData, veWomData])
  useEffect(() => {
    if (chartData === undefined && fetchedChartData && !chartError) {
      updateChartData(fetchedChartData)
    }
  }, [chartData, chartError, fetchedChartData, updateChartData])
  useEffect(() => {
    async function fetch() {
      const data = await fetchVeWomTransactions(womTokenClient)
      if (data) {
        updateTransactions(data)
      }
    }
    if (!transactions) {
      fetch()
    }
  }, [transactions, updateTransactions, womTokenClient])
  useEffect(() => {
    async function fetchData() {
      const data = await fetchVeWomUser(womTokenClient)
      if (data) {
        updateTopUser(data)
      }
    }
    if (!topUser) {
      fetchData()
    }
  }, [transactions, updateTransactions, topUser, womTokenClient, updateTopUser])

  return null
}
