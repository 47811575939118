import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { DarkGreyCard } from 'components/Card'
import Loader from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { formatAmount } from 'utils/numbers'
import { shortenAddress, getEtherscanLink } from 'utils'
import { Label, ClickableText } from 'components/Text'
import { VeWomTransaction, VeWomTransactionType } from 'types'
import { formatTime } from 'utils/date'
import { RowFixed } from 'components/Row'
import { ExternalLink, TYPE } from 'theme'
import { PageButtons, Arrow } from 'components/shared'
import useTheme from 'hooks/useTheme'
import { useActiveNetworkVersion } from 'state/application/hooks'
import ArrowBack from '../../wombat-assets/icons/arrow-back-ios.svg'

const LargeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    overflow-x: scroll;
  }
  background: white;
  border-radius: 15px;
  padding: 20px;
`

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
  background: white;
  border: 1.5px solid white;
  opacity: 0.8
  border-radius: 15px;
  font-family: 'Work Sans', sans-serif;
  font-size: 10px;
  padding: 15px 25px;
  font-family: 'Work Sans', sans-serif;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    overflow-x: scroll;
  }
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  padding: 5px 10px;
  grid-template-columns: 280px 1fr 1fr repeat(3, 1fr);
  font-color: #30291d;

  @media screen and (max-width: 600px) {
    grid-template-columns: 100px 100px 150px repeat(2, 100px) 100px;
  }
`

const SORT_FIELD = {
  timestamp: 'timestamp',
  sender: 'sender',
  womAmount: 'womAmount',
  veWomAmount: 'veWomAmount',
  duration: 'duration',
}

const DataRow = ({ transaction, color }: { transaction: VeWomTransaction; color?: string }) => {
  const [activeNetwork] = useActiveNetworkVersion()
  const theme = useTheme()

  return (
    <ResponsiveGrid>
      <ExternalLink href={getEtherscanLink(transaction.hash, 'transaction', activeNetwork)}>
        <Label color={color} fontWeight={700} textAlign="right">
          {transaction.type === VeWomTransactionType.STAKE ? `Lock WOM` : `Unlock WOM`}
        </Label>
      </ExternalLink>
      <Label end={1} fontWeight={400} textAlign="right">
        {` ${formatAmount(transaction.womAmount)} WOM `}
      </Label>
      <Label end={1} fontWeight={400} textAlign="right">
        {` ${formatAmount(transaction.veWomAmount)} veWOM `}
      </Label>
      <Label end={1} fontWeight={400} textAlign="right">
        {transaction.type === VeWomTransactionType.STAKE ? `${transaction.duration / 86400} days` : '-'}
      </Label>
      <Label end={1} fontWeight={400} textAlign="right">
        <ExternalLink
          href={getEtherscanLink(transaction.sender, 'address', activeNetwork)}
          style={{ color: color ?? theme.blue1 }}
        >
          {shortenAddress(transaction.sender)}
        </ExternalLink>
      </Label>
      <Label end={1} fontWeight={400} textAlign="right">
        {formatTime(transaction.timestamp, 0)}
      </Label>
    </ResponsiveGrid>
  )
}

export default function VeWomTransactionTable({
  transactions,
  maxItems = 5,
  txFilter,
}: {
  transactions: VeWomTransaction[]
  maxItems?: number
  color?: string
  txFilter: VeWomTransactionType | undefined
}) {
  // theming
  const theme = useTheme()
  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.timestamp)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    let extraPages = 1
    if (transactions.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(transactions.length / maxItems) + extraPages)
  }, [maxItems, transactions])

  const sortedTransactions = useMemo(() => {
    return transactions
      ? transactions
          .slice()
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof VeWomTransaction] > b[sortField as keyof VeWomTransaction]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .filter((x) => {
            return txFilter === undefined || x.type === txFilter
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [transactions, maxItems, page, sortField, sortDirection, txFilter])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!transactions) {
    return <Loader />
  }

  return (
    <LargeWrapper>
      <Wrapper>
        <AutoColumn gap="8px">
          <ResponsiveGrid>
            <RowFixed></RowFixed>
            <ClickableText color={theme.wombatBrown0} end={1} onClick={() => handleSort(SORT_FIELD.womAmount)}>
              WOM Amount
            </ClickableText>
            <ClickableText color={theme.wombatBrown0} end={1} onClick={() => handleSort(SORT_FIELD.veWomAmount)}>
              veWOM Amount
            </ClickableText>
            <ClickableText color={theme.wombatBrown0} end={1} onClick={() => handleSort(SORT_FIELD.duration)}>
              Lock Period
            </ClickableText>
            <ClickableText color={theme.wombatBrown0} end={1} onClick={() => handleSort(SORT_FIELD.sender)}>
              Account {arrow(SORT_FIELD.sender)}
            </ClickableText>
            <ClickableText color={theme.wombatBrown0} end={1} onClick={() => handleSort(SORT_FIELD.timestamp)}>
              Time {arrow(SORT_FIELD.timestamp)}
            </ClickableText>
          </ResponsiveGrid>
          {sortedTransactions.map((t, i) => {
            if (t) {
              return (
                <React.Fragment key={i}>
                  <DataRow transaction={t} color={'#B672D6'} />
                </React.Fragment>
              )
            }
            return null
          })}
          {sortedTransactions.length === 0 ? <TYPE.main>No Transactions</TYPE.main> : undefined}
        </AutoColumn>
      </Wrapper>
      <PageButtons>
        <div
          onClick={() => {
            setPage(page === 1 ? page : page - 1)
          }}
        >
          <Arrow faded={page === 1 ? true : false}>
            <img height={14} src={ArrowBack} alt={'back icon'} />
          </Arrow>
        </div>
        <TYPE.body>{page}</TYPE.body>
        <div
          onClick={() => {
            setPage(page === maxPage ? page : page + 1)
          }}
        >
          <Arrow faded={page === maxPage ? true : false} style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}>
            <img height={14} src={ArrowBack} alt={'next icon'} />
          </Arrow>
        </div>
        <TYPE.body>{' of ' + maxPage}</TYPE.body>
      </PageButtons>
    </LargeWrapper>
  )
}
