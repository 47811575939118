import { ROUTE_INFO } from '../../constants/index'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useActiveNetworkVersion } from 'state/application/hooks'
import styled from 'styled-components'
import { networkPrefix } from 'utils/networkPrefix'
import Menu from 'components/Menu'
import { SupportedNetwork } from 'constants/networks'

const Container = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 2;
  bottom: 0;
  justify-content: space-around;
  background: white;
`

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const activeClassName = 'ACTIVE'
const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  flex-direction: column;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: 100%;
  padding: 15px;
  font-weight: 500;
  &.${activeClassName} {
    border-radius: 12px;
    color: ${({ theme }) => theme.yellow3};
  }
`

const StyledNavLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const BottomNavigationBar = () => {
  const [activeNewtork] = useActiveNetworkVersion()
  const { pathname: pathnameReactRouter } = useLocation()
  const inactiveColor = '#695B51'
  const activeColor = '#F3B71E'

  return (
    <Container>
      {ROUTE_INFO.filter((route) => {
        const activeNetwork = activeNewtork.id
        /** hide vevom page in ETH */
        return !(route.name === 'VEWOM' && SupportedNetwork.ETHEREUM === activeNetwork)
      }).map((route, index) => {
        const filledColor = pathnameReactRouter.includes(route.pathName) ? activeColor : inactiveColor
        return (
          <Tab key={index}>
            <StyledNavLink to={networkPrefix(activeNewtork) + route.pathName}>
              <StyledNavLogoContainer>
                <route.icon fill={filledColor} />
              </StyledNavLogoContainer>
              <span style={{ textAlign: 'center' }}>{route.name}</span>
            </StyledNavLink>
          </Tab>
        )
      })}
      <Tab>
        <Menu />
      </Tab>
    </Container>
  )
}
