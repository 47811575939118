import { AppState, AppDispatch } from '../index'
import { AssetData, AssetChartEntry } from './reducer'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAssetData, addAssetKeys, updateChartData, updateTransactions } from './actions'
import { isAddress } from 'ethers/lib/utils'
import { fetchAssetChartData } from 'data/assets/chartData'
import { fetchAssetTransactions } from 'data/assets/transactions'
import { Transaction } from 'types'
import { notEmpty } from 'utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ClientType, useActiveNetworkVersion, useDataClient } from 'state/application/hooks'
// format dayjs with the libraries that we need
dayjs.extend(utc)

export function useAllAssetData(): {
  [address: string]: { data: AssetData | undefined; lastUpdated: number | undefined }
} {
  const [activeNetwork] = useActiveNetworkVersion()
  return useSelector((state: AppState) => state.assets.byAddress[activeNetwork.id] ?? {})
}

export function useUpdateAssetData(): (assets: AssetData[]) => void {
  const dispatch = useDispatch<AppDispatch>()
  const [activeNetwork] = useActiveNetworkVersion()

  return useCallback(
    (assets: AssetData[]) => {
      dispatch(updateAssetData({ assets, networkId: activeNetwork.id }))
    },
    [activeNetwork.id, dispatch]
  )
}

export function useAddAssetKeys(): (addresses: string[]) => void {
  const dispatch = useDispatch<AppDispatch>()
  const [activeNetwork] = useActiveNetworkVersion()
  return useCallback(
    (assetAddresses: string[]) => dispatch(addAssetKeys({ assetAddresses, networkId: activeNetwork.id })),
    [activeNetwork.id, dispatch]
  )
}

export function useAssetDatas(addresses: string[] | undefined): AssetData[] | undefined {
  const allAssetData = useAllAssetData()

  const addAssetKeys = useAddAssetKeys()

  // if asset not tracked yet track it
  addresses?.map((a) => {
    if (!allAssetData[a]) {
      addAssetKeys([a])
    }
  })

  const data = useMemo(() => {
    if (!addresses) {
      return undefined
    }
    return addresses
      .map((a) => {
        return allAssetData[a]?.data
      })
      .filter(notEmpty)
  }, [addresses, allAssetData])

  return data
}

export function useAssetData(address: string | undefined): AssetData | undefined {
  const allAssetData = useAllAssetData()
  const addAssetKeys = useAddAssetKeys()
  // if invalid address return
  if (!address || !isAddress(address)) {
    return undefined
  }

  // if token not tracked yet track it
  if (!allAssetData[address]) {
    addAssetKeys([address])
  }

  // return data
  return allAssetData[address]?.data
}

/**
 * Get top pools addresses that token is included in
 * If not loaded, fetch and store
 * @param address
 */
export function useAssetChartData(address: string): AssetChartEntry[] | undefined {
  const dispatch = useDispatch<AppDispatch>()
  const [activeNetwork] = useActiveNetworkVersion()
  const asset = useSelector((state: AppState) => state.assets.byAddress[activeNetwork.id]?.[address])
  const chartData = asset.chartData
  const [error, setError] = useState(false)
  const dataClient = useDataClient()[ClientType.MAIN]

  useEffect(() => {
    async function fetch() {
      const { error, data } = await fetchAssetChartData(address, dataClient)
      if (!error && data) {
        dispatch(updateChartData({ assetAddress: address, chartData: data, networkId: activeNetwork.id }))
      }
      if (error) {
        setError(error)
      }
    }
    if (!chartData && !error) {
      fetch()
    }
  }, [address, dispatch, error, chartData, dataClient, activeNetwork.id])

  // return data
  return chartData
}

/**
 * Get top pools addresses that asset is included in
 * If not loaded, fetch and store
 * @param address
 */
export function useAssetTransactions(address: string): Transaction[] | undefined {
  const dispatch = useDispatch<AppDispatch>()
  const [activeNetwork] = useActiveNetworkVersion()
  const asset = useSelector((state: AppState) => state.assets.byAddress[activeNetwork.id]?.[address])
  const transactions = asset.transactions
  const [error, setError] = useState(false)
  const dataClient = useDataClient()[ClientType.MAIN]

  useEffect(() => {
    async function fetch() {
      const { error, data } = await fetchAssetTransactions(address, dataClient)
      if (error) {
        setError(true)
      } else if (data) {
        dispatch(updateTransactions({ assetAddress: address, transactions: data, networkId: activeNetwork.id }))
      }
    }
    if (!transactions && !error) {
      fetch()
    }
  }, [activeNetwork.id, address, dataClient, dispatch, error, transactions])

  // return data
  return transactions
}
