import Row from 'components/Row'
import useBreakpoints from 'hooks/useBreakpoints'
import useTheme from 'hooks/useTheme'
import React from 'react'
import { TYPE } from 'theme'
import PythLogo from '../../assets/images/pyth-logo.png'
function PoweredBy() {
  const theme = useTheme()
  const { isMobileLg } = useBreakpoints()

  return (
    <Row justify="center" align="center" marginBottom={`${isMobileLg ? '7rem' : '3rem'}`}>
      <TYPE.subHeader mr="24px" color={theme.wombatBrown1}>
        Powered by
      </TYPE.subHeader>
      <img src={PythLogo} alt="pyth logo" />
    </Row>
  )
}

export default PoweredBy
