import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { DarkGreyCard } from 'components/Card'
import Loader from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { formatDollarAmount, formatAmount } from 'utils/numbers'
import { getEtherscanLink, shortenAddress } from 'utils'
import { Label, ClickableText } from 'components/Text'
import { Transaction, TransactionType } from 'types'
import { formatTime } from 'utils/date'
import { ExternalLink, TYPE } from 'theme'
import { PageButtons, Arrow, Break } from 'components/shared'
import useTheme from 'hooks/useTheme'
import HoverInlineText from 'components/HoverInlineText'
import { useActiveNetworkVersion } from 'state/application/hooks'
import ArrowBack from '../../wombat-assets/icons/arrow-back-ios.svg'
import { RowFixed } from 'components/Row'
import { patchTransactionKlayToKaia } from '../../data/assets/patch-transaction'

const LargeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    overflow-x: scroll;
  }
  background-color: #faf7ef;
  border: #ef893c solid 2px;
  border-radius: 15px;
  padding: 20px;
`

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
  background-color: #faf7ef;
  border: 0 solid white;
  border-radius: 13px;
  padding: 25px 25px;
  font-family: 'Work Sans', sans-serif;
  overflow-x: hidden;

  @media screen and (max-width: 600px) {
    overflow-x: scroll;
  }
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 280px 1fr 1.5fr repeat(2, 1fr);

  @media screen and (max-width: 600px) {
    grid-template-columns: 100px 100px 200px repeat(2, 100px);
  }
`

const SORT_FIELD = {
  amountUSD: 'amountUSD',
  timestamp: 'timestamp',
  sentFrom: 'sentFrom',
  amountToken0: 'amountToken0',
  amountToken1: 'amountToken1',
}

const DataRow = ({ transaction, color }: { transaction: Transaction; color?: string }) => {
  const abs0 = Math.abs(transaction.amountToken0)
  const abs1 = Math.abs(transaction.amountToken1)
  const outputTokenSymbol = transaction.token1Symbol
  const inputTokenSymbol = transaction.token0Symbol
  const theme = useTheme()
  const [activeNetwork] = useActiveNetworkVersion()
  return (
    <ResponsiveGrid>
      <ExternalLink href={getEtherscanLink(transaction.hash, 'transaction', activeNetwork)}>
        <Label color={color ?? theme.blue1} fontWeight={700}>
          {transaction.type === TransactionType.MINT
            ? `Deposit ${transaction.token0Symbol}`
            : transaction.type === TransactionType.SWAP
            ? `Swap ${inputTokenSymbol} for ${outputTokenSymbol}`
            : `Withdraw ${transaction.token0Symbol}`}
        </Label>
      </ExternalLink>
      <Label end={1} fontWeight={400}>
        {formatDollarAmount(transaction.amountUSD)}
      </Label>
      <Label end={1} fontWeight={400}>
        <HoverInlineText
          text={
            `${formatAmount(abs0)}  ${transaction.token0Symbol}` +
            `${
              transaction.type === TransactionType.SWAP ? ` > ${formatAmount(abs1)}  ${transaction.token1Symbol}` : ''
            }`
          }
          maxCharacters={36}
          textAlign="right"
        />
      </Label>
      <Label end={1} fontWeight={400}>
        <ExternalLink
          href={getEtherscanLink(transaction.sentFrom, 'address', activeNetwork)}
          style={{ color: color ?? theme.blue1 }}
        >
          {shortenAddress(transaction.sentFrom, 2)}
        </ExternalLink>
      </Label>
      <Label end={1} fontWeight={400} textAlign="right">
        {formatTime(transaction.timestamp, 0)}
      </Label>
    </ResponsiveGrid>
  )
}

export default function TransactionTable({
  transactions,
  maxItems = 10,
  txFilter,
}: {
  transactions: Transaction[]
  maxItems?: number
  color?: string
  txFilter?: TransactionType | undefined
}) {
  // theming
  const theme = useTheme()
  transactions = transactions.map(patchTransactionKlayToKaia)

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.timestamp)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    let extraPages = 1
    if (transactions.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(transactions.length / maxItems) + extraPages)
  }, [maxItems, transactions])

  const sortedTransactions = useMemo(() => {
    return transactions
      ? transactions
          .slice()
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof Transaction] > b[sortField as keyof Transaction]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .filter((x) => {
            return txFilter === undefined || x.type === txFilter
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [transactions, maxItems, page, sortField, sortDirection, txFilter])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!transactions) {
    return <Loader />
  }

  return (
    <LargeWrapper>
      <Wrapper>
        <AutoColumn gap="16px">
          <ResponsiveGrid>
            <RowFixed></RowFixed>
            <ClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.amountUSD)} end={1}>
              Total Value {arrow(SORT_FIELD.amountUSD)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.amountToken0)}>
              Token Amount {arrow(SORT_FIELD.amountToken0)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.sentFrom)}>
              Account {arrow(SORT_FIELD.sentFrom)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.timestamp)}>
              Time {arrow(SORT_FIELD.timestamp)}
            </ClickableText>
          </ResponsiveGrid>
          <Break />

          {sortedTransactions.map((t, i) => {
            if (t) {
              return (
                <React.Fragment key={i}>
                  <DataRow transaction={t} color={'#EF893C'} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          {sortedTransactions.length === 0 ? <TYPE.main>No Transactions</TYPE.main> : undefined}
        </AutoColumn>
      </Wrapper>
      <PageButtons>
        <div
          onClick={() => {
            setPage(page === 1 ? page : page - 1)
          }}
        >
          <Arrow faded={page === 1 ? true : false}>
            <img height={14} src={ArrowBack} alt={'back icon'} />
          </Arrow>
        </div>
        <TYPE.body>{page}</TYPE.body>
        <div
          onClick={() => {
            setPage(page === maxPage ? page : page + 1)
          }}
        >
          <Arrow faded={page === maxPage ? true : false} style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}>
            <img height={14} src={ArrowBack} alt={'next icon'} />
          </Arrow>
        </div>
        <TYPE.body>{' of ' + maxPage}</TYPE.body>
      </PageButtons>
    </LargeWrapper>
  )
}
