import React, { useMemo, useEffect } from 'react'
import { ColorBackground, PageWrapper } from 'pages/styled'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import AssetTable from 'components/assets/AssetTable'
import { useAllAssetData } from 'state/assets/hooks'
import { notEmpty, PoolLabels } from 'utils'
import { WorkSans } from 'components/shared'

export default function AssetsOverview() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const allTokens = useAllAssetData()
  const formattedTokens = useMemo(() => {
    return Object.values(allTokens)
      .map((t) => t.data)
      .filter(notEmpty)
  }, [allTokens])

  const filteredAsset = formattedTokens.filter((asset) => {
    return (
      !!asset &&
      asset.token.toLowerCase() != '0xe85afccdafbe7f2b096f268e31cce3da8da2990a' && // aBNBc
      asset.token.toLowerCase() != '0x4db5a66e937a9f4473fa95b1caf1d1e1d62e29ea' && // WETH
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? asset.tvlUSD > 500
        : true,
      asset.pool !== PoolLabels.BNB
    )
  })

  return (
    <>
      <ColorBackground />
      <PageWrapper>
        <AutoColumn gap="lg">
          <WorkSans>
            <TYPE.main>All Assets</TYPE.main>
          </WorkSans>
          <AssetTable assetDatas={filteredAsset} maxItems={10} />
        </AutoColumn>
      </PageWrapper>
    </>
  )
}
