import React from 'react'
import LineChart from 'components/LineChart/alt'
import styled from 'styled-components'
import useBreakpoints from 'hooks/useBreakpoints'

interface VeWomLineChart {
  data: {
    time: string
    value: number
  }[]
  value: number | undefined
  label: string | undefined
  setValue?: React.Dispatch<React.SetStateAction<number | undefined>> | undefined
  setLabel?: React.Dispatch<React.SetStateAction<string | undefined>> | undefined
}

export const WhiteLineChart = styled(LineChart)`
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 0px;
`

export const VeWomLineChart = ({ data, value, label, setLabel, setValue }: VeWomLineChart) => {
  const { isTabletXLg } = useBreakpoints()

  return (
    <>
      {isTabletXLg ? (
        <WhiteLineChart
          data={data}
          color={'wombatPurple0'}
          minHeight={240}
          value={value}
          label={label}
          setValue={setValue}
          setLabel={setLabel}
          height={200}
        />
      ) : (
        <WhiteLineChart
          data={data}
          color={'wombatPurple0'}
          minHeight={240}
          value={value}
          label={label}
          setValue={setValue}
          setLabel={setLabel}
          height={300}
        />
      )}
    </>
  )
}
