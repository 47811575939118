import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import gql from 'graphql-tag'
import { VeWomChartDayData } from 'types'
import { ClientType, useActiveNetworkVersion, useDataClient, useWomTokenClient } from 'state/application/hooks'
import { useState, useEffect } from 'react'
import { BNBNetworkInfo } from 'constants/networks'
const ONE_DAY_UNIX = 24 * 60 * 60

const VEWOM_CHART = gql`
  query veWomDayDatas($skip: Int!) {
    veWomDayDatas(first: 1000, skip: $skip, orderBy: id, orderDirection: asc, subgraphError: allow) {
      dayID: id
      dailyMinted
      dailyBurned
      dailyStaked
      dailyUnstaked
      dailyTotalSupply
    }
  }
`

interface VeWomData {
  dayID: string
  dailyMinted: string
  dailyBurned: string
  dailyTotalSupply: string
  dailyStaked: string
  dailyUnstaked: string
}

interface VeWomChartResults {
  veWomDayDatas: VeWomData[]
}

async function fetchVeWomChartData(client: ApolloClient<NormalizedCacheObject>) {
  let veWomData: VeWomData[] = []

  let error = false

  let allVeWomFound = false
  try {
    let skip = 0
    while (!allVeWomFound) {
      const {
        data: chartResData,
        error,
        loading,
      } = await client.query<VeWomChartResults>({
        query: VEWOM_CHART,
        variables: { skip },
        fetchPolicy: 'cache-first',
      })
      if (!loading) {
        skip += 1000
        if (chartResData.veWomDayDatas.length < 1000 || error) {
          allVeWomFound = true
        }
        if (chartResData) {
          veWomData = veWomData.concat(chartResData.veWomDayDatas)
        }
      }
    }
    skip = 0
  } catch {
    error = true
  }

  if (veWomData) {
    const formattedData = veWomData.reduce((accum: { [date: number]: VeWomChartDayData }, dayData) => {
      const dayID = parseInt(dayData.dayID)
      const dayMint = dayData.dailyMinted
      const dayBurn = dayData.dailyBurned
      const dayTotalSupply = dayData.dailyTotalSupply
      const dayStakedWom = dayData.dailyStaked
      const dayUnstakedWom = dayData.dailyUnstaked
      accum[dayID] = {
        date: parseInt((dayID * ONE_DAY_UNIX).toFixed(0)),
        dayMint: parseFloat(dayMint),
        dayBurn: parseFloat(dayBurn),
        dayTotalSupply: parseFloat(dayTotalSupply),
        dayLockedWom: parseFloat(dayStakedWom),
        dayUnlockedWom: parseFloat(dayUnstakedWom),
      }
      return accum
    }, {})
    return {
      data: Object.values(formattedData),
      error: false,
    }
  } else {
    return {
      data: undefined,
      error,
    }
  }
}

/**
 * Fetch historic chart data
 */
export function useFetchVeWomChartData(): {
  error: boolean
  data: VeWomChartDayData[] | undefined
} {
  const [data, setData] = useState<{ [network: string]: VeWomChartDayData[] | undefined }>()
  const [error, setError] = useState(false)
  const tokenClient = useWomTokenClient()
  const [activeNetwork] = useActiveNetworkVersion()

  const indexedData = data?.[activeNetwork.id]
  useEffect(() => {
    async function fetch() {
      const { data, error } = await fetchVeWomChartData(tokenClient)
      if (data && !error) {
        setData({
          [activeNetwork.id]: data,
        })
      } else if (error) {
        setError(true)
      }
    }
    if (!error && !indexedData) {
      fetch()
    }
  }, [data, error, tokenClient, activeNetwork.id, indexedData])

  return { error, data: indexedData }
}
