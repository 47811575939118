import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import gql from 'graphql-tag'
import { Transaction, TransactionType } from 'types'
import { formatTokenSymbol } from 'utils/tokens'
import { Swap, Deposit, Withdraw } from 'data/protocol/transactions'

const GLOBAL_TRANSACTIONS = (address: string) => {
  const queryString = `
  {
    swapsAs0: swaps(first: 500, where: { fromAsset: "${address}" }, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      fromToken {
        id
        symbol
      }
      toToken {
        id
        symbol
      }
      sentFrom
      fromAmount
      toAmount
      amount: amountUSD
    }
    swapsAs1: swaps(first: 500, where: { toAsset: "${address}" }, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      fromToken {
        id
        symbol
      }
      toToken {
        id
        symbol
      }
      sentFrom
      fromAmount
      toAmount
      amount: amountUSD
    }
    deposits(first: 500, where: { asset: "${address}" }, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      token {
        id
        symbol
      }
      sentFrom
      amount
      amountUSD
    }
    withdraws(first: 500, where: { asset: "${address}" }, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      token {
        id
        symbol
      }
      sentFrom
      amount
      amountUSD
    }
  }
  `
  return gql(queryString)
}

interface TransactionResults {
  swapsAs0: Swap[]
  swapsAs1: Swap[]
  deposits: Deposit[]
  withdraws: Withdraw[]
}

export async function fetchAssetTransactions(
  address: string,
  client: ApolloClient<NormalizedCacheObject>
): Promise<{ data: Transaction[] | undefined; error: boolean; loading: boolean }> {
  try {
    const { data, error, loading } = await client.query<TransactionResults>({
      query: GLOBAL_TRANSACTIONS(address),
      fetchPolicy: 'cache-first',
    })

    if (error) {
      return {
        data: undefined,
        error: true,
        loading: false,
      }
    }

    if (loading && !data) {
      return {
        data: undefined,
        error: false,
        loading: true,
      }
    }

    const formattedSwapsAs0 = data.swapsAs0.map((m: Swap) => {
      const swapEntry = {
        hash: m.id,
        type: TransactionType.SWAP,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.fromToken.symbol,
        token1Symbol: m.toToken.symbol,
        token0Address: m.fromToken.id,
        token1Address: m.toToken.id,
        amountUSD: parseFloat(m.amount),
        amountToken0: parseFloat(m.fromAmount),
        amountToken1: parseFloat(m.toAmount),
      }
      return swapEntry
    }, [])

    const formattedSwapsAs1 = data.swapsAs1.map((m: Swap) => {
      const swapEntry = {
        hash: m.id,
        type: TransactionType.SWAP,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.fromToken.symbol,
        token1Symbol: m.toToken.symbol,
        token0Address: m.fromToken.id,
        token1Address: m.toToken.id,
        amountUSD: parseFloat(m.amount),
        amountToken0: parseFloat(m.fromAmount),
        amountToken1: parseFloat(m.toAmount),
      }
      return swapEntry
    }, [])
    const formattedDeposits = data.deposits.map((m: Deposit) => {
      const depositEntry = {
        type: TransactionType.MINT,
        hash: m.id,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.token.symbol,
        token1Symbol: m.token.symbol,
        token0Address: m.token.id,
        token1Address: m.token.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount),
        amountToken1: parseFloat(m.amount),
      }
      return depositEntry
    }, [])
    const formattedWithdraws = data.withdraws.map((m: Withdraw) => {
      const withdrawEntry = {
        type: TransactionType.BURN,
        hash: m.id,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.token.symbol,
        token1Symbol: m.token.symbol,
        token0Address: m.token.id,
        token1Address: m.token.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount),
        amountToken1: parseFloat(m.amount),
      }
      return withdrawEntry
    }, [])

    return {
      data: [...formattedWithdraws, ...formattedDeposits, ...formattedSwapsAs0, ...formattedSwapsAs1],
      error: false,
      loading: false,
    }
  } catch {
    return {
      data: undefined,
      error: true,
      loading: false,
    }
  }
}
